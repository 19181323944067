
 
   main {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,
    url('./images/home-bg.jpg');
    background-size: cover;
    background-position: center; */
    min-height:90vh;
  }

  a {
    text-decoration: none;
  }
 
 .text-oblique {
  font-style :oblique;
 }

 #app-container {
   letter-spacing: 0.15rem;
  }
  

 .link-cursor-pointer{
   cursor: pointer;
 }

 #header {
  color: rgb(221, 221, 218);
  margin: 2rem 0;
  letter-spacing: 0.15rem;
 }

 .header-card {
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,
   url('./images/home-bg.jpg');
   background-size: cover;
   background-position: center;
 }

 #contact {
   margin: 2rem 0;
  letter-spacing: 0.15rem;
 }

 .map-container {
height: 400px;
}
 
.map-sidebar {
background-color: rgba(35, 55, 75, 0.9);
color: #fff;
padding: 6px 12px;
font-family: monospace;
z-index: 1;
position: absolute;
top: 0;
left: 0;
margin: 12px;
border-radius: 4px;
}

.resume-text {
  padding-left: 2rem;
}